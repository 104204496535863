
import * as React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { inject } from "mobx-react";

import { Button, Gallery, GalleryPageCollection, Slider } from "components"
import { PAGE_VIEW_EVENT, getFluidImageSharp, getLanguageValue, getFixedImageSharp } from "utils";
import { SquidexConfig, SquidexGalleryOverviewPage, SquidexProductOverview, SquidexLandingPageB, SquidexLandingPageBSidecontentDe } from 'generated/gatsbyApiTypes';

import "./index.less"
import ctaImage from "../static/hello_black.svg"
import { TraumwandPageComponent } from 'utils/interfaces';

export interface QueryData {
  config: SquidexConfig;
  galleryOverview: SquidexGalleryOverviewPage;

  productOverview: SquidexProductOverview;
  main: SquidexLandingPageB;
}

@inject("visitorStore")
export default class LandingV2Page extends TraumwandPageComponent<QueryData> {
  componentWillMount() {
    this.props.visitorStore.logEvent(PAGE_VIEW_EVENT, {
      location: this.props.location,
      pageType: "landing"
    });
  }
  render() {
    const me = this.props.data.main;
    return (
      <>
        <section id="intro" className="flex justify-between mt2">
          {/* <div className="dib w-100 w-60-ns">
            <Slider className="mt4" nodes={me.sliderImages} clickToEnlargeText="" excludeLightBox />
          </div> */}
          <div id="hero" className="w-100 w-60-ns overflow-hidden relative" >
            <Img className="w-100 h-100 dib" fluid={getFluidImageSharp(me.imgHero)} />
            <div className="absolute w-100 h-100 top-0 left-0 pa4 inline-flex items-start flex-column justify-around">
              <h1 className="white mt0-ns w-100">{getLanguageValue(me.txtHeroHeader)}</h1>
              <p className="white dn db-ns flex-grow-1">{getLanguageValue(me.txtHeroBody)}</p>
              <div className="w-100 dn db-ns">
                <Button size={4} label="Galerie" color="theme" className="mr2 mt2" href={this.props.data.galleryOverview.slug.iv.toLowerCase()} />
                <Button size={4} label="Produkte" color="theme" className="mr2 mt2" href={this.props.data.productOverview.slug.iv.toLowerCase()} />
                {/* <Button size={4} label={getLanguageValue(me.txtHowItWorks)} className="mt2" color="theme" href="#steps" /> */}
              </div>
            </div>
          </div>
          <div className="dn dib-ns w-32">
            <h3 className="mt0 tc">{getLanguageValue(me.txtTopPick)}</h3>
            <Gallery nodes={[me.topPick]} single navigate={this.props.navigate} />
          </div>
        </section>

        <div className="w-100 db dn-ns mt2">
          <Button size={4} label="Galerie" color="theme" className="mr2" href={this.props.data.galleryOverview.slug.iv.toLowerCase()} />
          <Button size={4} label="Produkte" color="theme" className="mr2" href={this.props.data.productOverview.slug.iv.toLowerCase()} />
          {/* <Button size={4} label={getLanguageValue(me.txtHowItWorks)} className="mt2" color="theme" href="#steps" /> */}
        </div>
        <section id="steps" className="mb2">
          <h1 className="mb0">{getLanguageValue(me.txtHowItWorks)}</h1>
          <div className="w-100 inline-flex flex-column flex-row-ns justify-between mr2">
            <div id="step1" className="flex flex-column">
              <h3 className="mb2 mt2">{getLanguageValue(me.txtCol1Header)}</h3>
              <div style={{ borderTop: "2px solid black" }} />
              <p className="mt2 mt0 flex-grow-1">{getLanguageValue(me.txtCol1Body)}</p>
            </div>
            <div id="step2" className="flex flex-column">
              <h3 className="mb2 mt0 mt2-ns">{getLanguageValue(me.txtCol2Header)}</h3>
              <div style={{ borderTop: "2px solid black" }} />
              <p className="mt2 mt0 flex-grow-1">{getLanguageValue(me.txtCol2Body)}</p>
            </div>
          </div>
          <div className="w-100 inline-flex flex-column flex-row-ns justify-between">
            <div id="step3" className="flex flex-column">
              <h3 className="mb2 mt0">{getLanguageValue(me.txtCol3Header)}</h3>
              <div style={{ borderTop: "2px solid black" }} />
              <p className="mt2 flex-grow-1">{getLanguageValue(me.txtCol3Body)}</p>
            </div>
            <div id="step4" className="flex flex-column">
              <h3 className="mb2 mt0">{getLanguageValue(me.txtCol4Header)}</h3>
              <div style={{ borderTop: "2px solid black" }} />
              <p className="mt2 flex-grow-1">{getLanguageValue(me.txtCol4Body)}</p>
            </div>
          </div>
        </section>
        <section id="collection" className={"mt4" + (me.galleries.length === 0 ? " dn" : "")}>
          <h1 className="">{getLanguageValue(me.txtCollection)}</h1>
          <GalleryPageCollection nodes={me.galleries} navigate={this.props.navigate} />
        </section>
        <section id="bestseller" className={"mt2" + (me.bestsellers.length === 0 ? " dn" : "")}>
          <h1>{getLanguageValue(me.txtBestseller)}</h1>
          <Gallery nodes={me.bestsellers} navigate={this.props.navigate} />
        </section>
        <section id="cta" className="full-width bg-light-gray">
          <div className="flex flex-column flex-row-ns mwPage pa3 pa0-l pt4-l pb4-l">
            <div className="w-100 w-50-ns mr5">
              <h1 className="mt0">{getLanguageValue(me.txtCtaHeader)}</h1>
              <div dangerouslySetInnerHTML={
                { __html: `${getLanguageValue(me.txtCtaBody).html}` }
              } />
              {me.ctaButtons.iv.map(
                (b, i) =>
                  <Button size={5} className="mr2 mt2 " label={b.label} href={b.href} color="white" textColor="black" inversed={true} key={`ctaButton-${i}`} />
              )}
            </div>
            <div className="w-50 dn db-ns">
              <img src={ctaImage} height="230" style={{ display: "block", margin: "auto", transform: "matrix(-1, 0, 0, 1, 0, 0)" }} />
            </div>
          </div>
        </section>
      </>
    )
  }
}

export const pageQuery = graphql`
{
  config: squidexConfig(identity: { eq: "config"}) {
    pageShopSlug {
      iv
    }
    allowImageUpload {
      iv
    }
  }
  galleryOverview: squidexGalleryOverviewPage(identity: {eq: "galleryOverview"}) {
    slug {
      iv
    }
  }
  productOverview: squidexProductOverview(identity: {eq: "productOverviewPage"}) {
    slug {
      iv
    }
  }
  main: squidexLandingPageB (identity: {eq: "landing"}){
    imgHero {
      childImageSharp {
        fluid(maxWidth: 1600) { 
        ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    sliderImages {
      childImageSharp {
        fixed(width: 640) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    txtTopPick {
      de
      nl
      en
    }
    txtHowItWorks {
      de
      nl
      en
    }
    topPick {
      name {
        iv
      }
      description {
        iv
      }
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }     
          original {
            src
            width
            height
          }
        }
      }
    }
    txtHeroHeader {
      de
      nl
      en
    }
    txtHeroBody {
      de
      nl
      en
    }
    txtCol1Header {
      de
      nl
      en
    }
    txtCol1Body {
      de
      nl
      en
    }
    txtCol2Header {
      de
      nl
      en
    }
    txtCol2Body {
      de
      nl
      en
    }
    txtCol3Header {
      de
      nl
      en
    }
    txtCol3Body {
      de
      nl
      en
    }
    txtCol4Header {
      de
      nl
      en
    }
    txtCol4Body {
      de
      nl
      en
    }
    galleries {
      name {
        de
        nl
        en
      }
      headerImage {
        childImageSharp {
          fluid(maxWidth: 1600) { 
          ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      slug {
        iv
      }
    }
    sidecontent {
      de {
        txt
        img {
          childImageSharp {
            fixed(height: 250, width: 250) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
      nl {
        txt
        img {
          childImageSharp {
            fixed(height: 250, width: 250) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
      en {
        txt
        img {
          childImageSharp {
            fixed(height: 250, width: 250) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
    bestsellers {
      id
      name {
        iv
      }
      description {
        iv
      }
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }     
          original {
            src
            width
            height
          }
        }
      }
    }
    txtCollection {
      de
      nl
      en
    }
    txtBestseller {
      de
      nl
      en
    }
    txtCtaHeader {
      de
      nl
      en
    }
    txtCtaBody {
      de {
        html
      }
      nl {
        html
      }
      en {
        html
      }
    }
    ctaButtons {
      iv {
        label
        href
      }
    }
  }
}
`
